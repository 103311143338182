<template>
    <section id="advantages">

        <div class="container">

            <h3 class="fw-bold mb-4 text-justify wow animate__animated animate__backInDown animate__slow" data-wow-offset="1" data-wow-delay="0.1s">
                إليك مميزات الامتياز التجاري STEAMdemy</h3>

            <!-- For Large Screen -->

            <div id="content_wrapper"
                class="d-none d-sm-none d-md-none d-lg-block d-xl-block wow animate__animated animate__backInUp animate__slow" data-wow-offset="1" data-wow-delay="0.2s">

                <div class="row gy-4">

                    <div class="col-lg-6 col-xl-4 d-flex align-items-stretch">
                        <div class="card">
                            <img src="@/assets/images/commercial_concession/advantages/advantage_1.webp" class="card-img-top"
                                alt="intercultural-kids-working-pairs-while-girls-controlling-virtual-presentation">
                            <div class="card-body">
                                <h5 class="card-title fw-bold">التجهيزات والأثاث</h5>
                                <p class="card-text">نضمن لك أن جهتك التعليمية ستكون  مناسبة لتنفيذ نهج STEAM التعليمي
                                    ومشجعة على التعلم عن طريق توفير STEAMademy لتصميم داخلي فريد للجهات التعليمية.
                                    نجهز المعامل بكل المعدات ونوفر كل التجهيزات المناسبة مثل وحدات التخزين وطاولات المنافسة
                                    وأجهزة الحاسب الآلي والشاشات التفاعلية.</p>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-xl-4 d-flex align-items-stretch">
                        <div class="card">
                            <img src="@/assets/images/commercial_concession/advantages/advantage_2.webp" class="card-img-top"
                                alt="metal-3d-printer-with-abstract-lines">
                            <div class="card-body">
                                <h5 class="card-title fw-bold">الأدوات والأجهزة</h5>
                                <p class="card-text">نوفر الأدوات والأجهزة اللازمة للتعلم وتطبيق نتائج التعلم من خلال مشاريع
                                    واقعية، تشمل هذه الأدوات مجموعات الروبوتات والدوائر الكهربائية وإلكترونيات تطبيق إنترنت
                                    الأشياء والطابعات ثلاثية الأبعاد.</p>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-xl-4 d-flex align-items-stretch">
                        <div class="card">
                            <img src="@/assets/images/commercial_concession/advantages/advantage_3.webp" class="card-img-top"
                                alt="">
                            <div class="card-body">
                                <h5 class="card-title fw-bold">الأدلة وجودة التشغيل</h5>
                                <p class="card-text">نوفر لك جميع أدلة التشغيل لتقدم نهج STEAM التعليمي بأفضل كفاءة تشغيل،
                                    تشمل أدلة الإجراءات التشغيلية متابعة سير عملية التعلم ونظام تقييم الطلاب والمدربين
                                    والموظفين بالإضافة لأدلة الصيانة الدورية وتحضير المعامل والبحث والتطوير.</p>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-xl-4 d-flex align-items-stretch">
                        <div class="card">
                            <img src="@/assets/images/commercial_concession/advantages/advantage_4.webp" class="card-img-top"
                                alt="father-sons-making-robot">
                            <div class="card-body">
                                <h5 class="card-title fw-bold">البرمجيات التعليمية</h5>
                                <p class="card-text">نقدم كل البرمجيات التي تحتاجها في عملية التعليم أو إنشاء المشاريع أو
                                    حتى متابعة الطلاب والأهل:
                                    برامج التصميم الهندسي مثل: AutoCAD, Tinkercad, SolidWorks
                                    اللغات البرمجية. C# , C++, Python
                                    تطبيقات متابعة الأهل.
                                    منصات الابتكار ودراسات الجدوى.</p>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-xl-4 d-flex align-items-stretch">
                        <div class="card">
                            <img src="@/assets/images/commercial_concession/advantages/advantage_5.webp" class="card-img-top"
                                alt="tech-devices-icons-connected-digital-planet-earth">
                            <div class="card-body">
                                <h5 class="card-title fw-bold">اختبارات STEAM الدولية</h5>
                                <p class="card-text">نقدم اختبارات STEAM الدولية لتتمكن من قياس جودة العملية التعليمية سواءً
                                    للطلاب أو المدربين أو الجهات التعليمية.</p>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-xl-4 d-flex align-items-stretch">
                        <div class="card">
                            <img src="@/assets/images/commercial_concession/advantages/advantage_6.webp" class="card-img-top"
                                alt="disabled-man-shows-financial-diagrams-office">
                            <div class="card-body">
                                <h5 class="card-title fw-bold">تأهيل المدربين</h5>
                                <p class="card-text">نقدم برامج تدريب متخصصة لمعلمين نهج STEAM التعليمي سواءً كانوا تابعين
                                    للجهات التعليمية أو مدربين منفردين ، تشمل عملية التأهيل تدريبًا على ما يلى:
                                    المفاهيم الأساسية في STEAM
                                    استراتيجيات التعليم النشط والتفاعلي
                                    التكنولوجيا في التعليم
                                    القيادة وإدارة المدارس STEAM</p>
                            </div>
                        </div>
                    </div>

                </div>

            </div>

            <!-- For Small Screen -->

            <div id="content_wrapper_mobile"
                class="d-block d-sm-block d-md-block d-lg-none d-xl-none wow animate__animated animate__backInUp animate__slow" data-wow-offset="1" data-wow-delay="0.2s">

                <div id="carouselExampleSlidesOnly" class="carousel slide" data-bs-ride="carousel">

                    <div class="carousel-inner">

                        <div class="carousel-item active" data-bs-interval="5000">
                            <div class="card">
                                <img src="@/assets/images/commercial_concession/advantages/advantage_1.webp"
                                    class="card-img-top"
                                    alt="intercultural-kids-working-pairs-while-girls-controlling-virtual-presentation">
                                <div class="card-body">
                                    <h5 class="card-title fw-bold">التجهيزات والأثاث</h5>
                                    <p class="card-text">نضمن لك أن جهتك التعليمية ستكون  مناسبة لتنفيذ نهج STEAM التعليمي
                                        ومشجعة على التعلم عن طريق توفير STEAMademy لتصميم داخلي فريد للجهات التعليمية.
                                        نجهز المعامل بكل المعدات ونوفر كل التجهيزات المناسبة مثل وحدات التخزين وطاولات
                                        المنافسة
                                        وأجهزة الحاسب الآلي والشاشات التفاعلية.</p>
                                </div>
                            </div>
                        </div>

                        <div class="carousel-item" data-bs-interval="5000">
                            <div class="card">
                                <img src="@/assets/images/commercial_concession/advantages/advantage_2.webp"
                                    class="card-img-top" alt="metal-3d-printer-with-abstract-lines">
                                <div class="card-body">
                                    <h5 class="card-title fw-bold">الأدوات والأجهزة</h5>
                                    <p class="card-text">نوفر الأدوات والأجهزة اللازمة للتعلم وتطبيق نتائج التعلم من خلال
                                        مشاريع
                                        واقعية، تشمل هذه الأدوات مجموعات الروبوتات والدوائر الكهربائية وإلكترونيات تطبيق
                                        إنترنت
                                        الأشياء والطابعات ثلاثية الأبعاد.</p>
                                </div>
                            </div>
                        </div>

                        <div class="carousel-item" data-bs-interval="5000">
                            <div class="card">
                                <img src="@/assets/images/commercial_concession/advantages/advantage_3.webp"
                                    class="card-img-top" alt="">
                                <div class="card-body">
                                    <h5 class="card-title fw-bold">الأدلة وجودة التشغيل</h5>
                                    <p class="card-text">نوفر لك جميع أدلة التشغيل لتقدم نهج STEAM التعليمي بأفضل كفاءة
                                        تشغيل،
                                        تشمل أدلة الإجراءات التشغيلية متابعة سير عملية التعلم ونظام تقييم الطلاب والمدربين
                                        والموظفين بالإضافة لأدلة الصيانة الدورية وتحضير المعامل والبحث والتطوير.</p>
                                </div>
                            </div>
                        </div>

                        <div class="carousel-item" data-bs-interval="5000">
                            <div class="card">
                                <img src="@/assets/images/commercial_concession/advantages/advantage_4.webp"
                                    class="card-img-top" alt="father-sons-making-robot">
                                <div class="card-body">
                                    <h5 class="card-title fw-bold">البرمجيات التعليمية</h5>
                                    <p class="card-text">نقدم كل البرمجيات التي تحتاجها في عملية التعليم أو إنشاء المشاريع
                                        أو
                                        حتى متابعة الطلاب والأهل:
                                        برامج التصميم الهندسي مثل: AutoCAD, Tinkercad, SolidWorks
                                        اللغات البرمجية. C# , C++, Python
                                        تطبيقات متابعة الأهل.
                                        منصات الابتكار ودراسات الجدوى.</p>
                                </div>
                            </div>
                        </div>

                        <div class="carousel-item" data-bs-interval="5000">
                            <div class="card">
                                <img src="@/assets/images/commercial_concession/advantages/advantage_5.webp"
                                    class="card-img-top" alt="tech-devices-icons-connected-digital-planet-earth">
                                <div class="card-body">
                                    <h5 class="card-title fw-bold">اختبارات STEAM الدولية</h5>
                                    <p class="card-text">نقدم اختبارات STEAM الدولية لتتمكن من قياس جودة العملية التعليمية
                                        سواءً
                                        للطلاب أو المدربين أو الجهات التعليمية.</p>
                                </div>
                            </div>
                        </div>

                        <div class="carousel-item" data-bs-interval="5000">
                            <div class="card">
                                <img src="@/assets/images/commercial_concession/advantages/advantage_6.webp"
                                    class="card-img-top" alt="disabled-man-shows-financial-diagrams-office">
                                <div class="card-body">
                                    <h5 class="card-title fw-bold">تأهيل المدربين</h5>
                                    <p class="card-text">نقدم برامج تدريب متخصصة لمعلمين نهج STEAM التعليمي سواءً كانوا
                                        تابعين
                                        للجهات التعليمية أو مدربين منفردين ، تشمل عملية التأهيل تدريبًا على ما يلى:
                                        المفاهيم الأساسية في STEAM
                                        استراتيجيات التعليم النشط والتفاعلي
                                        التكنولوجيا في التعليم
                                        القيادة وإدارة المدارس STEAM</p>
                                </div>
                            </div>
                        </div>

                    </div>

                    <!-- https://www.youtube.com/watch?v=BrCz-FlPGgk -->

                    <!-- Carousel controls -->

                    <div class="d-flex justify-content-center">

                        <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleSlidesOnly"
                            data-bs-slide="next">
                            <font-awesome-icon :icon="['fas', 'fa-chevron-right']" />
                        </button>

                        <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleSlidesOnly"
                            data-bs-slide="prev">
                            <font-awesome-icon :icon="['fas', 'fa-chevron-left']" />
                        </button>

                    </div>

                </div>

            </div>

        </div>

    </section>
</template>

<script>
import WOW from "wow.js";
export default {
    name: 'Advantages',
    mounted() {
        new WOW().init();
    },
}
</script>

<style lang="scss" scoped>
#advantages {

    padding-top: 40px;
    padding-bottom: 40px;

    .container {

        h3 {
            font-family: 'Bahij_TheSansArabic_Bold';
            color: $Green;
            // text-align: right;
            font-size: 24px;
            // font-weight: 500;
            line-height: normal;
        }

        // For Large Screen

        #content_wrapper {

            .card {

                background: $Gray;

                border: none;

                .card-body {

                    .card-title {
                        color: $Mov;
                        text-align: right;
                        font-size: 18px;
                        // font-weight: 500;
                        line-height: normal;
                    }

                    .card-text {
                        color: $Black;
                        text-align: justify;
                        font-size: 16px;
                        font-weight: 400;
                        line-height: normal;
                        text-align: justify;
                    }

                }

            }

        }

        // For Small Screen

        #content_wrapper_mobile {

            .carousel {

                .carousel-inner {

                    .carousel-item {

                        .card {

                            background: $Gray;

                            border: none;

                            height: 560px;

                            @include breakpoints(x-small) {
                                height: 500px;
                            }

                            @include breakpoints(small) {
                                height: 500px;
                            }

                            @include breakpoints(medium) {
                                height: 580px;
                            }

                            margin-left: 5px;
                            margin-right: 5px;

                            .card-body {

                                h5 {
                                    color: $Mov;
                                    text-align: right;
                                    font-size: 18px;
                                    // font-weight: 500;
                                    line-height: normal;
                                }

                                p {
                                    color: $Black;
                                    text-align: justify;
                                    font-size: 16px;
                                    font-weight: 400;
                                    line-height: normal;
                                    text-align: justify;
                                }

                            }

                        }

                    }

                }

                .carousel-control-prev,
                .carousel-control-next {
                    position: static;
                    padding-top: 16px;

                }

                .carousel-control-prev svg,
                .carousel-control-next svg {
                    color: $Orange;
                    font-size: 24px;
                }

            }

        }

    }

}
</style>