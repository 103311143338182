<template>

    <!-- Start Contact Us -->
    <ContactUs />
    <!-- End Contact Us -->

    <!-- Start Footer -->
    <Footer />
    <!-- End Footer -->
    
</template>

<script>

import ContactUs from '@/components/contact_us/ContactUs.vue';

export default {
    name: 'ContactUsView',
    components: {
        ContactUs,
    }
}
</script>

<style scoped lang="scss">

</style>