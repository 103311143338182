<template>
    <section id="field">
        <div class="container">

            <h3 class="fw-bold mb-4 wow animate__animated animate__backInDown animate__slow" data-wow-offset="1" data-wow-delay="0.1s">
                مجالات
                STEAM التطبيقية</h3>

            <!-- In Large Screen -->

            <div id="content_wrapper"
                class="d-none d-sm-none d-md-none d-lg-block d-xl-block wow animate__animated animate__backInUp animate__slow" data-wow-offset="1" data-wow-delay="0.2s">

                <div class="field_ele">
                    <div class="image_wrapper">
                        <img src="@/assets/images/home/fields/field_1.webp" alt="">
                    </div>
                    <h5 class="title_one text-center fw-bold mt-3">الاستدامة والبيئة</h5>
                    <hr />
                    <div class="img_wrapper">
                        <img src="@/assets/images/fields/fields/1.webp" alt="">
                    </div>
                    <div class="field_txt text_one m-3 p-3">
                        <p>هدفنا هو تدريب الطلاب على إيجاد حلول للمشكلات البيئية وتطبيق مبادئ الاستدامة
                            في حياتهم اليومية.
                        </p>
                    </div>
                </div>

                <div class="field_ele">
                    <div class="image_wrapper">
                        <img src="@/assets/images/home/fields/field_2.webp" alt="">
                    </div>
                    <h5 class="title_two text-center fw-bold mt-3">الفضاء والطيران</h5>
                    <hr />
                    <div class="img_wrapper">
                        <img src="@/assets/images/fields/fields/2.webp" alt="">
                    </div>
                    <div class="field_txt text_two m-3 p-3">
                        <p>نهدف إلى تزويد الطلاب بالمعرفة والمهارات المتعلقة بتكنولوجيا الفضاء
                            والطيران.</p>
                    </div>
                </div>

                <div class="field_ele">
                    <div class="image_wrapper">
                        <img src="@/assets/images/home/fields/field_3.webp" alt="">
                    </div>
                    <h5 class="title_three text-center fw-bold mt-3">الطاقة</h5>
                    <hr />
                    <div class="img_wrapper">
                        <img src="@/assets/images/fields/fields/3.webp" alt="">
                    </div>
                    <div class="field_txt text_three m-3 p-3">
                        <p>نعلم الطلاب المفاهيم الأساسية عن الطاقة المتجددة وتوليد الطاقة ليكونوا
                            قادرين على بناء مشاريع
                            عملية قادرة على حل مشكلات الطاقة.</p>
                    </div>
                </div>

                <div class="field_ele">
                    <div class="image_wrapper">
                        <img src="@/assets/images/home/fields/field_4.webp" alt="">
                    </div>
                    <h5 class="title_four text-center fw-bold mt-3">الاتصالات والإلكترونيات</h5>
                    <hr />
                    <div class="img_wrapper">
                        <img src="@/assets/images/fields/fields/4.webp" alt="">
                    </div>
                    <div class="field_txt text_four m-3 p-3">
                        <p>ندرب الطلاب على المفاهيم الأساسية لتقنيات الاتصالات والإلكترونيات ليكونوا
                            قادرين على تصميم
                            الأجهزة الإلكترونية وإنشاء حلول تقنية في مجال الاتصالات.</p>
                    </div>
                </div>

                <div class="field_ele">
                    <div class="image_wrapper">
                        <img src="@/assets/images/home/fields/field_5.webp" alt="">
                    </div>
                    <h5 class="title_five text-center fw-bold mt-3">الذكاء الاصطناعي والروبوتات</h5>
                    <hr />
                    <div class="img_wrapper">
                        <img src="@/assets/images/fields/fields/5.webp" alt="">
                    </div>
                    <div class="field_txt text_five m-3 p-3">
                        <p>يطبق الطلاب مخرجات نهج STEAM التعليمي في مشاريع متعلقة بالذكاء الاصطناعي
                            وتعلم الآلة ليكونوا
                            قادرين على دمج مفاهيم STEAM الأساسية بالتقنيات التكنولوجية الحديثة القائمة على الذكاء الاصطناعي.
                        </p>
                    </div>
                </div>

            </div>

            <!-- In Mobile Screen -->

            <div id="content_wrapper_mobile"
                class="d-block d-sm-block d-md-block d-lg-none d-xl-none wow animate__animated animate__backInUp animate__slow" data-wow-offset="1" data-wow-delay="0.2s">

                <div id="carouselExampleSlidesOnly" class="carousel slide" data-bs-ride="carousel">

                    <div class="carousel-inner">

                        <div class="carousel-item active" data-bs-interval="5000">
                            <div class="image_wrapper">
                                <img src="@/assets/images/home/fields/field_1.webp" class="d-block w-100" alt="">
                            </div>
                            <h5 class="title_one text-center fw-bold mt-3 mb-4">الاستدامة والبيئة</h5>
                            <!-- <hr class="m-auto" />
                            <div class="img_wrapper">
                                <img src="@/assets/images/fields/fields/1.webp" alt="">
                            </div> -->
                            <div class="field_txt text_one mt-3 mx-auto p-3">
                                <p>هدفنا هو تدريب الطلاب على إيجاد حلول للمشكلات البيئية وتطبيق مبادئ
                                    الاستدامة
                                    في حياتهم اليومية.
                                </p>
                            </div>
                        </div>

                        <div class="carousel-item" data-bs-interval="5000">
                            <div class="image_wrapper">
                                <img src="@/assets/images/home/fields/field_2.webp" class="d-block w-100" alt="">
                            </div>
                            <h5 class="title_two text-center fw-bold mt-3 mb-4">الفضاء والطيران</h5>
                            <!-- <hr class="m-auto" />
                            <div class="img_wrapper">
                                <img src="@/assets/images/fields/fields/2.webp" alt="">
                            </div> -->
                            <div class="field_txt text_two mt-3 mx-auto p-3">
                                <p>نهدف إلى تزويد الطلاب بالمعرفة والمهارات المتعلقة بتكنولوجيا الفضاء
                                    والطيران.</p>
                            </div>
                        </div>

                        <div class="carousel-item" data-bs-interval="5000">
                            <div class="image_wrapper">
                                <img src="@/assets/images/home/fields/field_3.webp" class="d-block w-100" alt="">
                            </div>
                            <h5 class="title_three text-center fw-bold mt-3 mb-4">الطاقة</h5>
                            <!-- <hr class="m-auto" />
                            <div class="img_wrapper">
                                <img src="@/assets/images/fields/fields/3.webp" alt="">
                            </div> -->
                            <div class="field_txt text_three mt-3 mx-auto p-3">
                                <p>نعلم الطلاب المفاهيم الأساسية عن الطاقة المتجددة وتوليد الطاقة
                                    ليكونوا
                                    قادرين على بناء مشاريع
                                    عملية قادرة على حل مشكلات الطاقة.</p>
                            </div>
                        </div>

                        <div class="carousel-item" data-bs-interval="5000">
                            <div class="image_wrapper">
                                <img src="@/assets/images/home/fields/field_4.webp" alt="">
                            </div>
                            <h5 class="title_four text-center fw-bold mt-3 mb-4">الاتصالات والإلكترونيات</h5>
                            <!-- <hr class="m-auto" />
                            <div class="img_wrapper">
                                <img src="@/assets/images/fields/fields/4.webp" class="d-block w-100" alt="">
                            </div> -->
                            <div class="field_txt text_four mt-3 mx-auto p-3">
                                <p>ندرب الطلاب على المفاهيم الأساسية لتقنيات الاتصالات والإلكترونيات
                                    ليكونوا
                                    قادرين على تصميم
                                    الأجهزة الإلكترونية وإنشاء حلول تقنية في مجال الاتصالات.</p>
                            </div>
                        </div>

                        <div class="carousel-item" data-bs-interval="5000">
                            <div class="image_wrapper">
                                <img src="@/assets/images/home/fields/field_5.webp" alt="">
                            </div>
                            <h5 class="title_five text-center fw-bold mt-3 mb-4">الذكاء الاصطناعي والروبوتات</h5>
                            <!-- <hr class="m-auto" />
                            <div class="img_wrapper">
                                <img src="@/assets/images/fields/fields/5.webp" class="d-block w-100" alt="">
                            </div> -->
                            <div class="field_txt text_five mt-3 mx-auto p-3">
                                <p>يطبق الطلاب مخرجات نهج STEAM التعليمي في مشاريع متعلقة بالذكاء
                                    الاصطناعي
                                    وتعلم الآلة ليكونوا
                                    قادرين على دمج مفاهيم STEAM الأساسية بالتقنيات التكنولوجية الحديثة القائمة على الذكاء
                                    الاصطناعي.
                                </p>
                            </div>
                        </div>

                    </div>

                    <!-- https://www.youtube.com/watch?v=BrCz-FlPGgk -->

                    <!-- Carousel controls -->

                    <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleSlidesOnly"
                        data-bs-slide="next">
                        <font-awesome-icon :icon="['fas', 'fa-chevron-right']" />
                    </button>

                    <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleSlidesOnly"
                        data-bs-slide="prev">
                        <font-awesome-icon :icon="['fas', 'fa-chevron-left']" />
                    </button>

                </div>

            </div>

        </div>
    </section>
</template>

<script>
import WOW from "wow.js";
export default {
    name: 'Fields',
    mounted() {
        new WOW().init();
    },
}
</script>

<style lang="scss" scoped>
#field {

    margin-top: 72px;

    padding-top: 40px;
    padding-bottom: 40px;

    h3 {
        font-family: 'Bahij_TheSansArabic_Bold';
        color: $Mov;
        text-align: right;
        font-size: 24px;
        // font-weight: 500;
        line-height: normal;

        @include breakpoints(x-small) {
            text-align: center;
        }

        @include breakpoints(small) {
            text-align: center;
        }

        @include breakpoints(medium) {
            text-align: center;
        }

    }

    // In Large Screen

    #content_wrapper {

        .field_ele {

            display: inline-flex;
            flex-direction: column;
            align-items: center;

            width: calc(100% / 5);

            .image_wrapper {

                height: 188px;

                @include breakpoints(large) {
                    height: 165px;
                }

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }

            }

            h5 {
                font-size: 16px;
                // font-weight: 500;
                line-height: normal;

                @include breakpoints(large) {
                    height: 50px;
                }

            }

            hr {
                width: 100%;
                height: 4px;
                color: $Black;
                background: $Black;
                margin-bottom: 0;
                opacity: 1;
            }

            .img_wrapper {

                width: 24px;
                height: 180px;

                margin-top: -15px;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            .field_txt {
                width: calc(100% - 32px);
                height: 275px;
                border-radius: 8px;
                color: $Black;
                line-height: normal;

                @include breakpoints(large) {
                    height: 410px;
                }

                @include breakpoints(x-Large) {
                    height: 330px;
                }

            }

        }

    }

    // In Mobile Screen

    #content_wrapper_mobile {

        #carouselExampleSlidesOnly {

            .carousel-inner {

                .carousel-item {

                    .image_wrapper {

                        height: 188px;

                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: contain;
                        }

                    }

                    h5 {
                        font-size: 16px;
                        // font-weight: 500;
                        line-height: normal;
                    }

                    /* hr {
                        width: 400px;
                        height: 4px;
                        color: $Black;
                        background: $Black;
                        opacity: 1;

                        @include breakpoints(x-small) {
                            width: 300px;
                        }

                    } */

                    /* .img_wrapper {

                        width: 24px;
                        height: 180px;

                        margin: -15px auto 0 auto;

                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    } */

                    .field_txt {
                        width: 400px;
                        border-radius: 8px;
                        color: $Black;
                        line-height: normal;

                        @include breakpoints(x-small) {
                            width: 300px;
                            height: 190px;
                        }

                        @include breakpoints(small) {
                            height: 140px;
                        }

                        @include breakpoints(medium) {
                            height: 140px;
                        }

                    }

                }

            }

            .carousel-control-next,
            .carousel-control-prev {
                svg {
                    font-size: 24px;
                    color: $Orange;
                }
            }

            .carousel-control-next {
                svg {
                    margin-right: -50px;
                }
            }

            .carousel-control-prev {
                svg {
                    margin-left: -50px;
                }
            }

        }

    }

}

// For All Screen

.title_one {
    color: $Green;
}

.title_two {
    color: $Pink;
}

.title_three {
    color: $Ylo;
}

.title_four {
    color: $Dark-blue;
}

.title_five {
    color: $Orange;
}

.text_one {
    background: $Green_Light;
}

.text_two {
    background: $Pink_Light;
}

.text_three {
    background: $Ylo_Light;
}

.text_four {
    background: $Blue_Light;
}

.text_five {
    background: $Orange_Light;
}
</style>